// import React from "react";
// import { Link } from "react-router-dom";
// import heroV from "../../../assets/images/home.mp4";
// import { motion } from "framer-motion";

// const Hero = () => {
//   const fadeIn = {
//     initial: { opacity: 0, y: 50 },
//     animate: { opacity: 1, y: 0 },
//     transition: { duration: 0.8 },
//   };

//   return (
//     <section className="relative h-[50rem] md:h-[60rem] lg:h-[70rem] flex justify-center items-center">
//       {/* Video Background */}
//       <video
//         className="absolute top-0 left-0 w-full h-full object-cover"
//         src={heroV}
//         autoPlay
//         loop
//         muted
//         playsInline
//       ></video>

//       {/* Overlay for darkening video and making text readable */}
//       <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>

//       {/* Centered Content on top of the video */}
//       <motion.div
//         className="relative z-10 flex flex-col items-center text-center p-4"
//         initial="initial"
//         animate="animate"
//         variants={fadeIn}
//         whileInView={{ opacity: 1, y: 0 }}
//         viewport={{ once: true }}
//       >
//         <motion.h1
//           className="text-4xl font-extrabold text-white sm:text-5xl md:text-6xl"
//           variants={fadeIn}
//           transition={{ delay: 0.2 }}
//         >
//           ONEKOO - COFFEE
//         </motion.h1>
//         <motion.p
//           className="mt-4 text-golden text-lg sm:text-xl md:text-2xl"
//           variants={fadeIn}
//           transition={{ delay: 0.4 }}
//         >
//           Taste Our Story!
//         </motion.p>
//         <motion.div
//           className="mt-8 flex flex-col sm:flex-row sm:justify-center gap-4"
//           variants={fadeIn}
//           transition={{ delay: 0.6 }}
//         >
//           <Link
//             onClick={() => window.scrollTo(0, 0)}
//             to={`/whatwedo`}
//             className="block w-full rounded bg-primary px-12 py-3 text-lg font-medium text-white shadow hover:text-white hover:bg-secondary focus:outline-none focus:ring active:text-rose-500 sm:w-auto"
//           >
//             Find Out More!
//           </Link>
//           <Link
//             onClick={() => window.scrollTo(0, 0)}
//             to={`/account`}
//             className="block w-full rounded bg-primary px-12 py-3 text-lg font-medium text-white shadow hover:text-white hover:bg-secondary focus:outline-none focus:ring active:text-rose-500 sm:w-auto"
//           >
//             Shop Now!
//           </Link>
//         </motion.div>
//       </motion.div>
//     </section>
//   );
// };

// export default Hero;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
// import video1 from "../../../assets/images/home.mp4";
import video2 from "../../../assets/images/video2.mp4";
import video3 from "../../../assets/images/video3.mp4";

const Hero = () => {
  // Array of video sources
  const videoSources = [video2, video3];

  // State to manage the current video index
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  // Effect to cycle through the videos
  useEffect(() => {
    const videoTimer = setInterval(() => {
      setCurrentVideoIndex(
        (prevIndex) => (prevIndex + 1) % videoSources.length
      );
    }, 10000); // Change video every 10 seconds

    // Cleanup interval on unmount
    return () => clearInterval(videoTimer);
  }, [videoSources.length]);

  const fadeIn = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8 },
  };

  return (
    <section className="relative h-[50rem] md:h-[60rem] lg:h-[70rem] flex justify-center items-center">
      {/* Video Background */}
      <video
        key={currentVideoIndex} // Ensure the video re-renders with the new source
        className="absolute top-0 left-0 w-full h-full object-cover"
        src={videoSources[currentVideoIndex]}
        autoPlay
        loop
        muted
        playsInline
      ></video>

      {/* Overlay for darkening video and making text readable */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>

      {/* Centered Content on top of the video */}
      <motion.div
        className="relative z-10 flex flex-col items-center text-center p-4"
        initial="initial"
        animate="animate"
        variants={fadeIn}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
      >
        <motion.h1
          className="text-4xl font-extrabold text-white sm:text-5xl md:text-6xl"
          variants={fadeIn}
          transition={{ delay: 0.2 }}
        >
          ONEKOO - COFFEE
        </motion.h1>
        <motion.p
          className="mt-4 text-golden text-lg sm:text-xl md:text-2xl"
          variants={fadeIn}
          transition={{ delay: 0.4 }}
        >
          Taste Our Story!
        </motion.p>
        <motion.div
          className="mt-8 flex flex-col sm:flex-row sm:justify-center gap-4"
          variants={fadeIn}
          transition={{ delay: 0.6 }}
        >
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to={`/whatwedo`}
            className="block w-full rounded bg-primary px-12 py-3 text-lg font-medium text-white shadow hover:text-white hover:bg-secondary focus:outline-none focus:ring active:text-rose-500 sm:w-auto"
          >
            Find Out More!
          </Link>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to={`/account`}
            className="block w-full rounded bg-primary px-12 py-3 text-lg font-medium text-white shadow hover:text-white hover:bg-secondary focus:outline-none focus:ring active:text-rose-500 sm:w-auto"
          >
            Shop Now!
          </Link>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Hero;
