import React from "react";
import hero1 from "../../assets/images/Story/about us.png";

function Banner() {
  return (
    <div className="slider-container">
      <div className="relative overflow-hidden shadow-lg shadow-white">
        <img
          className="object-cover w-full h-[20rem] md:h-[40rem] lg:h-[40rem]"
          src={hero1}
          alt="Flower and sky"
        />
        <div className="absolute -top-20 md:top-0 left-0 w-full flex justify-center items-center pt-36 px-6 py-4">
          <h4 className="mt-10 md:mt-24 ml-6 md:ml-28 w-fit text-5xl md:text-7xl p-4 md:p-8 text-white font-bold tracking-tight">
            Our Story
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Banner;
