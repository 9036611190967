import React from "react";
import { motion } from "framer-motion";
import establishment from "../../assets/images/home1.png";
import Heart from "./Establishment/HistoryEstablishment";
import OnekooInauguration from "./Establishment/OnekooInauguration";

const Establishment = () => {
  return (
    <React.Fragment>
      <Heart />
      <OnekooInauguration />
    </React.Fragment>
  );
};

export default Establishment;
