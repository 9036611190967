import React from "react";
import ImageGallery from "./ImageGallery";
import FounderImages from "./FounderImages";
import ingu1 from "../../../assets/images/ingu1.jpg";
import ingu2 from "../../../assets/images/ingu2.jpg";
import ingu3 from "../../../assets/images/ingu3.png";

function OnekooInauguration() {
  const images = [
    {
      src: ingu1,
      alt: "Onekoo Coffee Plant Inauguration Image 1",
    },
    {
      src: ingu2,
      alt: "Onekoo Coffee Plant Inauguration Image 2",
    },
    {
      src: ingu3,
      alt: "Onekoo Coffee Plant Inauguration Image 3",
    },
  ];

  return (
    <main className="flex overflow-hidden flex-col items-center px-20 pt-32 py-12 h-auto bg-white max-md:px-5">
      <h1 className="text-4xl font-bold text-yellow-950 max-md:max-w-full">
        ONEKOO-COFFEE Plant Inauguration
      </h1>
      <section className="self-stretch mt-20 max-md:mt-10 max-md:max-w-full">
        <ImageGallery images={images} />
      </section>
      <p className="mt-11 text-2xl text-yellow-950 max-md:mt-10 max-md:max-w-full">
        The inauguration event of the Onekoo plant was attended by the President
        of Oromia, Shimelis Abdissa, and other Federal and Regional Officials
        who had been integral to the success of the project.
      </p>
      <h2 className="mt-12 text-4xl font-bold text-yellow-950 max-md:mt-10">
        Our Founders
      </h2>
      <FounderImages />
    </main>
  );
}

export default OnekooInauguration;
