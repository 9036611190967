import { useState } from "react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { motion } from "framer-motion";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    fullname: "",
    email: "",
    address: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const validate = () => {
    const newErrors = {};

    if (!formValues.fullname) {
      newErrors.fullname = "Fullname is required";
    }

    if (!formValues.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = "Invalid email";
    }

    if (!formValues.address) {
      newErrors.address = "Address is required";
    }

    if (!formValues.password) {
      newErrors.password = "Password is required";
    } else if (formValues.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters";
    }

    if (!formValues.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required";
    } else if (formValues.password !== formValues.confirmPassword) {
      newErrors.confirmPassword = "Passwords must match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      axios
        .post(`https://back.onekoocoffee.com/api/auth/register`, formValues)
        .then((response) => {
          // alert("Registered successfully!", response.data);

          console.log("Success:", response.data); // Log after successful response

          setShowSuccessPopup(true); // Show success popup
          setFormValues({
            fullname: "",
            email: "",
            address: "",
            password: "",
            confirmPassword: "",
          });
          setTimeout(() => {
            setShowSuccessPopup(false);
            navigate("/account"); // Hide success popup after 3 seconds
          }, 3000); // Changed to 3000 for 3 seconds
        })
        .catch((error) => {
          console.error(
            "Error Response:",
            error.response?.data.msg || error.message
          );
          setErrors((prevErrors) => ({
            ...prevErrors,
            api:
              error.response?.data.msg ||
              "An error occurred during registration",
          }));
          setShowErrorPopup(true); // Show error popup
          setTimeout(() => {
            setShowErrorPopup(false); // Hide error popup after 3 seconds
          }, 3000);
        });
    }
  };
  return (
    <>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          show: {
            opacity: 1,
          },
        }}
        initial="hidden"
        animate="show"
        className="w-[90%] font-mono md:w-[70%] m-auto my-24 mt-0 pt-32"
      >
        {/* Form header */}
        <motion.div
          variants={{
            hidden: { opacity: 0 },
            show: {
              opacity: 1,
              transition: {
                staggerChildren: 0.25,
              },
            },
          }}
          className="flex gap-x-6"
        >
          <motion.div
            variants={{
              hidden: { opacity: 0, x: -100 },
              show: {
                opacity: 1,
                x: 0,
                transition: {
                  duration: 1.3,
                  delay: 0.3,
                },
              },
            }}
            className="w-[50%] md:w-[30%] mb-2"
          >
            <h1 className="text-3xl md:text-5xl mb-2 text-left font-semibold text-amber-950">
              Welcome
            </h1>
            <p className="text-left text-sm md:text-base">
              Please fill out the form below to create an account
            </p>
          </motion.div>
          <motion.div
            variants={{
              hidden: { opacity: 0, y: -20 },
              show: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 1.3,
                  delay: 0.3,
                },
              },
            }}
            className="w-[70%] md:w-[80%] rounded-md shadow-lg shadow-orange-900 mt-1 md:mt-4 h-[30px] py-1 bg-amber-950"
          ></motion.div>
        </motion.div>
        {/* Form */}
        <form action="" onSubmit={handleSubmit}>
          <motion.div
            variants={{
              hidden: { opacity: 0, y: 100 },
              show: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 1.3,
                  delay: 0.3,
                },
              },
            }}
            className="mt-3 flex flex-col gap-y-4"
          >
            {/* Name input */}
            <div className="flex items-center gap-x-7 mb-3">
              <label
                className="text-base md:text-lg text-left w-[40%] md:w-[20%]"
                htmlFor="name"
              >
                Name
              </label>
              <div className="relative w-[70%] md:w-[50%]">
                <Input
                  name="fullname"
                  value={formValues.fullname}
                  onChange={handleChange}
                  className="w-full outline-none border-2 rounded-3xl border-yellow-900/30"
                  id="name"
                  placeholder="Enter full name"
                />
                {errors.fullname && (
                  <div className="absolute text-xs left-3 -bottom-4 text-red-400">
                    {errors.fullname}
                  </div>
                )}
              </div>
            </div>
            {/* Email input */}
            <div className="flex items-center gap-x-7 mb-3">
              <label
                className="text-base md:text-lg text-left w-[40%] md:w-[20%]"
                htmlFor="email"
              >
                Email
              </label>
              <div className="relative w-[70%] md:w-[50%]">
                <Input
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  type="email"
                  className="w-full border-2 rounded-3xl border-yellow-900/30"
                  id="email"
                  placeholder="Email"
                />
                {errors.email && (
                  <div className="absolute text-xs left-3 -bottom-4 text-red-400">
                    {errors.email}
                  </div>
                )}
              </div>
            </div>
            {/* Address input */}
            <div className="flex items-center gap-x-7 mb-3">
              <label
                className="text-base md:text-lg text-left w-[40%] md:w-[20%]"
                htmlFor="address"
              >
                Address
              </label>
              <div className="relative w-[70%] md:w-[50%]">
                <Input
                  name="address"
                  value={formValues.address}
                  onChange={handleChange}
                  className="w-full border-2 rounded-3xl border-yellow-900/30"
                  id="address"
                  placeholder="Addis Ababa, Ethiopia"
                />
                {errors.address && (
                  <div className="absolute text-xs left-3 -bottom-4 text-red-400">
                    {errors.address}
                  </div>
                )}
              </div>
            </div>
            {/* Password input */}
            <div className="flex items-center gap-x-7 mb-3">
              <label
                className="text-base md:text-lg text-left w-[40%] md:w-[20%]"
                htmlFor="pass"
              >
                Password
              </label>
              <div className="relative w-[70%] md:w-[50%]">
                <Input
                  name="password"
                  value={formValues.password}
                  onChange={handleChange}
                  type="password"
                  className="w-full border-2 rounded-3xl border-yellow-900/30"
                  id="pass"
                  placeholder="Password"
                />
                {errors.password && (
                  <div className="absolute text-xs left-3 -bottom-4 text-red-400">
                    {errors.password}
                  </div>
                )}
              </div>
            </div>
            {/* Confirm Password input */}
            <div className="flex items-center gap-x-7 mb-3">
              <label
                className="text-base md:text-lg text-left w-[40%] md:w-[20%]"
                htmlFor="confirmpass"
              >
                Confirm Password
              </label>
              <div className="relative w-[70%] md:w-[50%]">
                <Input
                  name="confirmPassword"
                  value={formValues.confirmPassword}
                  onChange={handleChange}
                  type="password"
                  className="w-full border-2 rounded-3xl border-yellow-900/30"
                  id="confirmpass"
                  placeholder="Confirm password"
                />
                {errors.confirmPassword && (
                  <div className="absolute text-xs left-3 -bottom-4 text-red-400">
                    {errors.confirmPassword}
                  </div>
                )}
              </div>
            </div>
            {/* Submit button */}
            <div className="flex justify-end">
              <Button
                variants={{
                  hidden: { opacity: 0, x: 100 },
                  show: {
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 2,
                      delay: 0.3,
                    },
                  },
                }}
                type="submit"
                className="hover:bg-[#612203] hover:scale-95 bg-transparent text-[#612203] font-semibold shadow-md shadow-orange-900 hover:text-white duration-500 border-2 border-[#612203] px-6 py-1 rounded-full"
              >
                Sign Up
              </Button>
            </div>
          </motion.div>
        </form>
        {/* Success popup */}
        {showSuccessPopup && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-50"
          >
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h2 className="text-2xl font-semibold mb-4">Success!</h2>
              <p className="mb-4">You have successfully registered.</p>
              <Button
                onClick={() => setShowSuccessPopup(false)}
                className="hover:bg-[#612203] bg-[#612203] text-white font-semibold px-4 py-2 rounded-full"
              >
                Close
              </Button>
            </div>
          </motion.div>
        )}
        {/* Error popup */}
        {showErrorPopup && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-50"
          >
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h2 className="text-2xl font-semibold mb-4">
                Registration Failed
              </h2>
              <p className="mb-4">
                There was an error with your registration.{" "}
                {errors && errors.api}.
              </p>
              <Button
                onClick={() => setShowErrorPopup(false)}
                className="hover:bg-[#612203] bg-[#612203] text-white font-semibold px-4 py-2 rounded-full"
              >
                Close
              </Button>
            </div>
          </motion.div>
        )}
      </motion.div>
    </>
  );
};

export default SignUp;
