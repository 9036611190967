import React from "react";
import Userlogin from "../../src/components/Login/Userlogin"


const Account = () => {
    return (
        <React.Fragment>
            <div className="self-center gap-10">
                <Userlogin/>
            </div>
        </React.Fragment>
    )
}

export default Account;