import React from "react";
import Hero from "../components/Home/Hero/Hero";
import Supply from "../components/Home/SupplyChain/SupplyChain";
import Product from "../components/Home/Our Coffee Products/CoffeeProducts";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <div>
      <motion.div
        className="h-auto"
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: false }}
      >
        <Hero />
      </motion.div>
      <motion.div
        className="h-full mb-[85rem] sm:mb-[68rem] md:mb-[30rem] lg:mb-[30rem]"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
        <Supply />
      </motion.div>
      <motion.div
        className="h-full"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
        <Product />
      </motion.div>
    </div>
  );
};

export default Home;
