import React, { useState } from "react";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const Conta = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_bbd5bjg", // Replace with your EmailJS service ID
        "template_ypwrlgw", // Replace with your EmailJS template ID
        formData,
        "MG2RNtpicEeYq2INV" // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSubmitted(true);
          setFormData({
            name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <main className="flex flex-col items-center px-6 pb-12 pt-32 bg-white md:px-20 h-auto overflow-hidden">
      <section className="w-full max-w-4xl bg-white p-8 rounded-lg shadow-md">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-6 text-center"
        >
          <h2 className="text-3xl font-bold text-primary mb-4">Contact</h2>
          <p className="text-lg text-golden">
            Have any questions or feedback? We'd love to hear from you! Fill out
            the form below, and we'll get back to you as soon as possible.
          </p>
        </motion.div>

        {isSubmitted ? (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center"
          >
            <h3 className="text-xl font-semibold text-green-600">
              Thank you for reaching out to us!
            </h3>
            <p className="text-gray-700">
              We appreciate your message and will get back to you soon.
            </p>
          </motion.div>
        ) : (
          <motion.form
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            onSubmit={handleSubmit}
            className="space-y-6"
          >
            <div className="flex flex-col">
              <label className="text-gray-700 text-lg mb-2" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="p-3 border border-gray-300 rounded-lg"
              />
            </div>

            <div className="flex flex-col">
              <label className="text-gray-700 text-lg mb-2" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="p-3 border border-gray-300 rounded-lg"
              />
            </div>

            <div className="flex flex-col">
              <label className="text-gray-700 text-lg mb-2" htmlFor="message">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                className="p-3 border border-gray-300 rounded-lg h-32"
              ></textarea>
            </div>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="w-full p-3 bg-primary text-white rounded-lg font-bold text-lg"
            >
              Send Message
            </motion.button>
          </motion.form>
        )}

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mt-10"
        >
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">
            Visit Us
          </h3>
          <div className="relative w-full h-64">
            <iframe
              title="Onekoo Coffee Location"
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d912.1194227766556!2d38.73593969644911!3d9.01130182112948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sonekoo%20coffee!5e0!3m2!1sen!2set!4v1719588032249!5m2!1sen!2set"
              className="absolute top-0 left-0 w-full h-full border-0 rounded-lg"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mt-10 flex justify-center space-x-6"
        >
          <a
            href="https://www.facebook.com/onekoocoffee"
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary hover:text-blue-600"
          >
            <FaFacebook size={32} />
          </a>
          <a
            href="https://www.instagram.com/onekoocoffee"
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary hover:text-pink-600"
          >
            <FaInstagram size={32} />
          </a>
          <a
            href="https://twitter.com/onekoocoffee"
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary hover:text-black"
          >
            <FaSquareXTwitter size={32} />
          </a>
          <a
            href="https://www.linkedin.com/company/onekoocoffee"
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary hover:text-blue-700"
          >
            <FaLinkedin size={32} />
          </a>
        </motion.div>
      </section>
    </main>
  );
};

export default Conta;
