import React from "react";
import hero from "../../assets/images/Story/hero1.jpg";

function AdBanner() {
  return (
    <div className="w-full mx-4">
      <div className="relative overflow-hidden shadow-lg shadow-white cursor-pointer">
        <img
          className="object-cover w-[100%] h-[3rem] md:h-[10rem]"
          src={hero}
          alt="Banner 1"
        />
      </div>
    </div>
  );
}

export default AdBanner;
