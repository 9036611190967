import React from "react";
import { motion } from "framer-motion";

const StrengthsAndWeaknesses = () => {
  return (
    <React.Fragment>
      <div className="bg-white py-10 pt-32">
        {/* Strengths Section */}
        <motion.div
          className="container mx-auto px-5 sm:px-10 md:px-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl text-primary font-semibold text-center">
            Strengths
          </h1>
          <ul className="list-disc mt-8 space-y-2 text-xl text-start">
            <li>
              Presence of huge potential of coffee productions of different
              varieties, as the market for consumables and factory products is
              supplied.
            </li>
            <li>
              Capacity to supply all types and varieties and quality of roasted
              coffee for factory input.
            </li>
            <li>
              Capacity to supply quality roasted coffee to the domestic and
              international markets.
            </li>
            <li>
              Leadership and staff with full knowledge of the organization's
              vision.
            </li>
            <li>
              Leaders with proven capacity for generating business ideas and
              translating those ideas into successful ventures.
            </li>
            <li>Conducive working environment for employees and leaders.</li>
            <li>
              Developed working systems and manuals, with some digitalized.
            </li>
            <li>Reputation among coffee roasters.</li>
            <li>Access to hard currency for any spare parts.</li>
            <li>
              Physical resources covering buildings, plants, and vehicles for
              mission execution.
            </li>
            <li>
              The business itself is a strength due to its high demand in the
              market.
            </li>
            <li>Sustainable supply.</li>
            <li>
              High-quality, traceable, certified coffee, organic, shade-grown
              coffee cherries from the heart of coffee’s origin.
            </li>
            <li>Producer, processor, and exporter.</li>
            <li>Quality and quantity control from seedling to cup.</li>
          </ul>
        </motion.div>

        {/* Weaknesses Section */}
        <motion.div
          className="container mx-auto px-5 sm:px-10 md:px-16 mt-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl text-primary font-semibold text-center">
            Weaknesses
          </h1>
          <ul className="list-disc mt-8 space-y-2 text-xl text-start">
            <li>
              Lack of staff in some functional units; lack of incentives to
              attract capable personnel from the labor market, especially from
              the respective industry.
            </li>
            <li>
              Failure to use financial opportunities for the benefit of its
              members, the union, and the country at large.
            </li>
            <li>Lower capacity utilization of the processing facilities.</li>
            <li>
              Gaps in digitized marketing, promotion, monitoring and
              evaluations, payments, etc.
            </li>
            <li>
              Gaps in lobbying government bodies with conflicting interests to
              reconcile the gaps that hinder progress.
            </li>
          </ul>
        </motion.div>
      </div>
    </React.Fragment>
  );
};

export default StrengthsAndWeaknesses;
