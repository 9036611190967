import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserSearch } from "lucide-react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Textarea } from "../ui/textarea";
import axios from "axios";

const Bank = ({
  searchedBank,
  searchedBankData,
  onSearchChange7,
  searchHandler7,
  bankExist,
  handleSubmit7,
  handleChange7,
  allBanks,
  errors,
}) => {
  const navigate = useNavigate();
  // const [image, setImage] = useState(null);
  // const [fileInputKey, setFileInputKey] = useState(Date.now());

  const handleUpdateBank = async (e) => {
    e.preventDefault();
    console.log(searchedBankData);
    const data = {
      id: searchedBankData.id,
      bank_name: searchedBankData.bank_name,
      acc_num: searchedBankData.acc_num,
    };
    // const bankImg = image ? image : data.image;

    // formdata.append("id", data.id);
    // formdata.append("bank_name", data.bank_name);
    // formdata.append("acc_num", data.acc_num);
    // formdata.append("image", bankImg);
    console.log(data);
    // console.log(formdata);
    axios
      .post(`https://back.onekoocoffee.com/bank/update-bank`, data)
      .then((res) => {
        console.log(res.data);
        alert("Bank Updated");
        navigate(0);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  return (
    <>
      <div className="flex relative w-full max-w-sm items-center space-x-2">
        <Input
          value={searchedBank}
          onChange={onSearchChange7}
          type="text"
          placeholder="Search"
        />
        <Button onClick={searchHandler7} type="">
          {" "}
          <UserSearch />
        </Button>
        {!bankExist && (
          <div className="absolute text-xs left-0 -bottom-4 text-red-400">
            bank does not Exist
          </div>
        )}
      </div>
      <form action="" onSubmit={handleSubmit7}>
        <div className="mt-3 flex flex-col gap-y-4">
          <div className="flex items-center gap-x-7 mb-3">
            <label
              className="text-base md:text-lg text-left w-[40%] md:w-[20%]"
              htmlFor="name"
            >
              Bank Name:
            </label>
            <div className="relative w-[70%] md:w-[50%]">
              <Input
                name="bank_name"
                value={bankExist && searchedBankData.bank_name}
                onChange={handleChange7}
                className="w-full outline-none border-2 rounded-3xl border-yellow-900/30"
                id="name"
                placeholder="Enter Bank Name"
              />
              {errors.fullname && (
                <div className="absolute text-xs left-3 -bottom-4 text-red-400">
                  {errors.fullname}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-x-7 mb-3">
            <label
              className="text-base md:text-lg text-left w-[40%] md:w-[20%]"
              htmlFor="acc"
            >
              Account Number:
            </label>
            <div className="relative w-[70%] md:w-[50%]">
              <Input
                name="acc_num"
                value={bankExist && searchedBankData.acc_num}
                onChange={handleChange7}
                className="w-full border-2 rounded-3xl border-yellow-900/30"
                id="acc"
                placeholder="Enter accounnt number"
              />
              {errors.email && (
                <div className="absolute text-xs left-3 -bottom-4 text-red-400">
                  {errors.email}
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-3 justify-end">
            <Button
              type="submit"
              className={`hover:bg-[#612203] hover:scale-95 bg-transparent text-[#612203] font-semibold shadow-md shadow-orange-900 hover:text-white duration-500 border-2 border-[#612203] px-6 py-1 rounded-full ${
                bankExist ? "hidden" : "block"
              }`}
            >
              Create Bank
            </Button>
            <Button
              onClick={handleUpdateBank}
              className={`hover:bg-[#612203] hover:scale-95 bg-transparent text-[#612203] font-semibold shadow-md shadow-orange-900 hover:text-white duration-500 border-2 border-[#612203] px-6 py-1 rounded-full ${
                bankExist ? "block" : "hidden"
              }`}
            >
              Update Bank
            </Button>
          </div>
        </div>
      </form>
      <div>
        <h1 className="text-left font-semibold text-xl">Banks List</h1>
        {allBanks && (
          <div className="flex flex-col gap-y-1">
            <div className="flex justify-between items-center border-t-2 border-b-2 p-2 mb-5 border-slate-200">
              <div className="text-left w-[50px]">ID</div>
              <div className="text-left w-[150px]">Bank Name</div>
              <div className="text-left w-[250px]">Acc_Num</div>
            </div>
            {allBanks.map((bank, index) => {
              return (
                <div className="" key={index}>
                  <div className="flex justify-between items-center bg-amber-600/10  border-b-2 px-1 py-2 border-slate-200">
                    <div className="text-left w-[50px]">{bank.id}</div>
                    <div className="text-left w-[150px]">{bank.bank_name}</div>
                    <div className="text-left w-[250px]">{bank.acc_num}</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default Bank;
