import React from "react";
import "./Userbutton.css"; // Make sure this path matches your actual CSS file path

const Button = ({ handleSubmit }) => {
  return (
    <button
      className="px-12 py-1 bg-white text-primary hover:bg-primary hover:text-white"
      onClick={() => {
        handleSubmit();
      }}
    >
      Login
      <div className="arrow-wrapper">
        <div className="arrow w-[100px]"></div>
      </div>
    </button>
  );
};

export default Button;
