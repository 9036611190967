import React from "react";

function LocationItem({ imageSrc, description, isReversed }) {
  const imageColumn = (
    <div
      className={`flex flex-col ${
        isReversed ? "ml-5" : ""
      } w-[28%] max-md:ml-0 max-md:w-full`}
    >
      <img
        loading="lazy"
        src={imageSrc}
        alt=""
        className="object-contain shrink-0 max-w-full rounded-full aspect-square w-[150px] max-md:mt-6"
      />
    </div>
  );

  const descriptionColumn = (
    <div
      className={`flex flex-col ${
        !isReversed ? "ml-5" : ""
      } w-[72%] max-md:ml-0 max-md:w-full`}
    >
      <div className="self-stretch px-3.5 py-6 my-auto w-full text-base text-white bg-yellow-800 max-md:pr-5 max-md:mt-10">
        {description}
      </div>
    </div>
  );

  return (
    <div className="mt-4 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col">
        {isReversed ? (
          <>
            {descriptionColumn}
            {imageColumn}
          </>
        ) : (
          <>
            {imageColumn}
            {descriptionColumn}
          </>
        )}
      </div>
    </div>
  );
}

export default LocationItem;
