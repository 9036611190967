import React from "react";

function ImageSection({ src, alt }) {
  return (
    <figure className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
      <img
        loading="lazy"
        src={src}
        alt={alt}
        className="object-contain w-full aspect-[1.8] max-md:mt-10 max-md:max-w-full"
      />
    </figure>
  );
}

export default ImageSection;
