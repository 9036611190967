import React from "react";

function CuppingStep({ imageSrc, description }) {
  return (
    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow text-xl text-white max-md:mt-10 max-md:max-w-full">
        <img
          loading="lazy"
          src={imageSrc}
          alt="Coffee cupping step"
          className="object-contain w-full aspect-[2.04] max-md:max-w-full"
        />
        <div className="px-6 pt-4 pb-6 mt-10 bg-secondary text-start max-md:px-5 max-md:mt-10 max-md:max-w-full">
          {description}
        </div>
      </div>
    </div>
  );
}

export default CuppingStep;
