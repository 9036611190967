import React from "react";
import LocationItem from "./LocationItem";

function LocationSection({
  title,
  description,
  locations,
  isReversed = false,
}) {
  return (
    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
      <div
        className={`flex flex-col ${
          isReversed ? "grow mt-16" : ""
        } text-yellow-950 max-md:mt-10`}
      >
        <h2
          className={`${
            isReversed ? "mr-9 max-md:mr-2.5" : "self-center"
          } text-4xl`}
        >
          {title}
        </h2>
        <p
          className={`${
            isReversed ? "mt-8" : "mt-11"
          } text-xl text-center max-md:mt-10`}
        >
          {description}
        </p>
      </div>
      <div className="flex flex-col w-full max-md:mt-10 max-md:max-w-full">
        {locations.map((location, index) => (
          <LocationItem
            key={index}
            imageSrc={location.imageSrc}
            description={location.description}
            isReversed={isReversed}
          />
        ))}
      </div>
    </div>
  );
}

export default LocationSection;
