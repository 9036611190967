import React from "react";
import CoffeeRoastType from "./CoffeeRoastType";
import { Link } from "react-router-dom";

const roastTypes = [
  { color: "bg-orange-300", label: "Light Roast" },
  { color: "bg-yellow-700", label: "Medium Roast" },
  { color: "bg-yellow-900", label: "Medium-Dark Roast" },
  { color: "bg-yellow-950", label: "Dark Roast" },
  { color: "bg-yellow-950", label: "French Dark Roast" },
  { color: "bg-stone-900", label: "Italian Dark Roast" },
];

const CoffeeRoastList = () => (
  <div className="mt-12 ml-14 max-w-full w-[495px]">
    <div className="flex flex-wrap gap-5">
      {roastTypes.map((roast, index) => (
        <CoffeeRoastType key={index} color={roast.color} label={roast.label} />
      ))}
    </div>

    <button className="self-stretch px-4 pt-1.5 pb-4 mt-10 bg-yellow-900 rounded-3xl text-xl text-white">
      <Link
        onClick={() => window.scrollTo(0, 0)}
        to={"/whatwedo"}
        // className="self-stretch px-1.5 pt-1.5 pb-4 mt-[32rem] bg-secondary rounded-3xl text-xl text-white"
      >
        Discover Our Coffee Journey
      </Link>
    </button>
  </div>
);

export default CoffeeRoastList;
