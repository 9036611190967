import React from "react";
import menu from "../assets/images/onekoo_coffee_menu_psyann_graphics_2024.png";

const Menu = () => {
  return (
    <div className="bg-cover pt-20">
      <img src={menu} alt="menu" />
    </div>
  );
};

export default Menu;
