import React from "react";
// import TextSection from "./TextSection";
import ImageSection from "./ImageSection";
import cup from "../../../assets/images/FarmtoCup/5.png";
import farm from "../../../assets/images/FarmtoCup/1.png";
import { Link } from "react-router-dom";

function SupplyChain() {
  return (
    <main className="flex flex-col px-12 pt-20 pb-7 bg-white max-md:px-5">
      <header className="self-center">
        <h1 className="text-4xl text-onekooblack">SupplyChain</h1>
        <p className="mt-6 text-2xl text-onekooblack font-italicGaramond">
          from farm to cup
        </p>
      </header>
      <section className="flex gap-5 max-md:flex-col max-md:max-w-full">
        {/* <TextSection
          content={textSections[0].content}
          readMore={textSections[0].readMore}
        /> */}
        <article className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow mt-14 text-xl max-md:mt-10 max-md:max-w-full">
            <p className="text-onekooblack max-md:max-w-full text-start">
              Experience the journey of our coffee from farm to cup, where
              tradition meets transparency. Onekoo Coffee, a product of the
              Oromia Coffee Farmers' Cooperative Union (OCFCU), showcases the
              seamless process of transforming green coffee from Oromia,
              Ethiopia into expertly roasted and packaged offerings.\n\nThe
              relationship between Onekoo Coffee and OCFCU is deeply rooted in
              heritage and quality. OCFCU sources premium green coffee from the
              lush regions of Oromia, ensuring each batch reflects the rich
              flavor profile and cultural significance of Ethiopian coffee. Our
              meticulous processing and packaging preserve the integrity and
              freshness of the beans, delivering exceptional coffee to both our
              cafés and customers.
            </p>
            <p className="self-start mt-5 text-onekooblack text-opacity-30 max-md:ml-2">
              <Link onClick={() => window.scrollTo(0, 0)} to={"/relationship"}>
                Read more about the relationship to OCFCU
              </Link>
            </p>
          </div>
        </article>
        <ImageSection src={farm} alt="Coffee supply chain illustration" />
      </section>
      <section className="flex gap-5 mt-5 max-md:flex-col max-md:mr-1 max-md:max-w-full">
        <ImageSection src={cup} alt="Coffee cafe illustration" />
        {/* <TextSection
          content={textSections[1].content}
          readMore={textSections[1].readMore}
        /> */}
        <article className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow mt-14 text-xl max-md:mt-10 max-md:max-w-full">
            <p className="text-onekooblack max-md:max-w-full text-start">
              Our cafés in Addis Ababa serve as a testament to this commitment.
              Here, you can enjoy a range of meticulously prepared coffee
              beverages, from robust espressos to delicate lattes, all embodying
              the essence of Ethiopian coffee culture. Each cup reflects our
              dedication to maintaining a transparent and traceable supply
              chain, allowing you to experience the full depth of Ethiopian
              coffee's legacy.\n\nBy choosing Onekoo Coffee, you embrace a
              narrative of quality and tradition, supported by a network that
              values every step of the coffee's journey. Discover the
              distinguished flavors and heritage that define our coffee, and
              enjoy a taste that bridges past and present.
            </p>
            <p className="self-start mt-5 text-onekooblack text-opacity-30 max-md:ml-2">
              <Link onClick={() => window.scrollTo(0, 0)} to={"/farm-to-cup"}>
                Read more about our coffee's journey
              </Link>
            </p>
          </div>
        </article>
      </section>
    </main>
  );
}

export default SupplyChain;
