import React from "react";
import meskela from "../../../assets/images/Tadesse Meskela.jpg";

function FounderImages() {
  const founders = [
    {
      src: meskela,
      alt: "Founder 1",
      name: "Tadesse Meskela",
    },
    {
      src: meskela,
      alt: "Founder 2",
      name: "Tadesse Meskela",
    },
  ];

  return (
    <div className="mt-14 max-w-full w-[741px] max-md:mt-10">
      <div className="flex gap-5 max-md:flex-col">
        {founders.map((founder, index) => (
          <div
            key={index}
            className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full"
          >
            <img
              loading="lazy"
              src={founder.src}
              alt={founder.alt}
              className="object-contain grow w-full aspect-[0.92] rounded-full max-md:mt-10"
            />
            <p className="text-2xl text-primary mt-[-4.5rem] w-[81%] self-center rounded-b-xl bg-secondary p-5">
              {founder.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FounderImages;
