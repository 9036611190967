// import React from "react";
// import { motion } from "framer-motion";
// import { IoCartOutline } from "react-icons/io5";
// import { Link, useNavigate } from "react-router-dom";

// const Cart = ({ cart }) => {
//   const navigate = useNavigate();
//   return (
//     <React.Fragment>
//       <div className="flex flex-col bg-secondary p2 mt-5 rounded-xl">
//         <p className="text-2xl text-white">Cart</p>
//         <hr className="self-center w-[80%]" />
//         <motion.div
//           className="text-white text-sm bg-secondary min-w-[200px] max-md:px-5 h-[30rem] p-2 overflow-auto"
//           initial={{ opacity: 0 }}
//           animate={{ opacity: 1 }}
//           transition={{ duration: 0.5 }}
//         >
//           {cart &&
//             cart.map((product, index) => {
//               return (
//                 <div>
//                   <motion.div
//                     className="flex gap-4 mt-6 max-md:mt-10"
//                     whileHover={{ scale: 1.05 }}
//                   >
//                     <IoCartOutline />
//                     <div className="self-start">{product.name}</div>
//                   </motion.div>
//                   <motion.div
//                     className="mt-2 max-md:max-w-full"
//                     whileHover={{ scale: 1.05 }}
//                   >
//                     {product.description}
//                   </motion.div>
//                 </div>
//               );
//             })}
//         </motion.div>
//         <button
//           onClick={() => {
//             navigate("/checkout", { state: cart });
//           }}
//           className="m-3 p2 text-center self-center bg-golden border border-primary rounded-xl"
//         >
//           Checkout
//         </button>
//       </div>
//     </React.Fragment>
//   );
// };

// export default Cart;

import React from "react";
import { motion } from "framer-motion";
import { IoCartOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

const Cart = ({ cart }) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="flex flex-col bg-secondary p-2 mt-5 rounded-xl">
        <p className="text-2xl text-white">Cart</p>
        <hr className="self-center w-[80%]" />
        <motion.div
          className="text-white text-sm bg-secondary min-w-[200px] max-md:px-5 h-[30rem] p-2 overflow-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {cart && cart.length > 0 ? (
            cart.map((product, index) => (
              <div key={index}>
                <motion.div
                  className="flex gap-4 mt-6 max-md:mt-10"
                  whileHover={{ scale: 1.05 }}
                >
                  <IoCartOutline />
                  <div className="self-start">{product.name}</div>
                </motion.div>
                <motion.div
                  className="mt-2 max-md:max-w-full"
                  whileHover={{ scale: 1.05 }}
                >
                  {product.description}
                </motion.div>
              </div>
            ))
          ) : (
            <div className="text-center">Your cart is empty</div>
          )}
        </motion.div>
        <button
          onClick={() => {
            console.log("Navigating to /checkout");
            navigate("/checkout", { state: cart });
          }}
          className="m-3 p-2 text-center self-center bg-golden border border-primary rounded-xl"
        >
          Checkout
        </button>
      </div>
    </React.Fragment>
  );
};

export default Cart;
