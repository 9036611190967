import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import PremiumPackaging from "./PremiumPackaging/PremiumPackaging";
import Cafes from "./cafes/CafeLocations";
import roastMachine from "../../assets/images/roasting.jpg";

const roastTypes = [
  { color: "bg-orange-300", label: "Light Roast" },
  { color: "bg-yellow-700", label: "Medium Roast" },
  { color: "bg-yellow-900", label: "Medium-Dark Roast" },
  { color: "bg-yellow-950", label: "Dark Roast" },
  { color: "bg-yellow-950", label: "French Dark Roast" },
  { color: "bg-stone-900", label: "Italian Dark Roast" },
];

const CoffeeRoastType = ({ color, label }) => (
  <div className="flex flex-col mt-10">
    <div className={`flex shrink-0 ${color} h-[39px]`} />
    <div className="mt-14 max-md:mt-10 text-xl text-white">{label}</div>
  </div>
);

const CoffeeRoastList = () => (
  <div className="mt-12 ml-14 max-w-full w-[495px]">
    <div className="flex flex-wrap gap-5">
      {roastTypes.map((roast, index) => (
        <CoffeeRoastType key={index} color={roast.color} label={roast.label} />
      ))}
    </div>

    {/* <button className="self-stretch px-4 pt-1.5 pb-4 mt-10 bg-yellow-900 rounded-3xl text-xl text-white">
      <Link
        onClick={() => window.scrollTo(0, 0)}
        to={"/whatwedo"}
        // className="self-stretch px-1.5 pt-1.5 pb-4 mt-[32rem] bg-secondary rounded-3xl text-xl text-white"
      >
        Discover Our Coffee Journey
      </Link>
    </button> */}
  </div>
);

const WeDo = () => {
  return (
    <section className="flex overflow-hidden flex-col items-center pt-7 bg-secondary">
      <h1 className="text-4xl text-white font-bold">What We Do</h1>
      <p className="mt-2 text-xl text-primary">
        Bringing Exceptional Coffee to You
      </p>
      <div className="self-stretch mt-5 w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-[31%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={roastMachine}
              alt="Coffee illustration"
              className="object-contain grow w-full aspect-[0.62] md:aspect-[3.62] lg:aspect-[3.62] max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col ml-5 w-[69%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col items-start px-14 py-16 mx-auto w-full bg-yellow-800 max-md:px-5 max-md:max-w-full">
              <p className="self-stretch text-xl text-white max-md:max-w-full">
                At Onekoo Coffee, we are passionate about delivering premium
                roasted and ground coffee that caters to every taste preference.
                Our diverse range includes:
              </p>
              <CoffeeRoastList />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const WhatWeDo = () => {
  return (
    <React.Fragment>
      <div className="pt-28 bg-white">
        <WeDo />
        <PremiumPackaging />
        <Cafes />
        <p className="text-start px-6 text-primary md:px-60 lg:px-60 pb-12">
          Our commitment is to deliver the finest coffee experience through both
          our premium packaging and exceptional café services. With each cup of
          Onekoo Coffee, you’re savoring not just a drink, but a tradition of
          quality and dedication.
        </p>
      </div>
    </React.Fragment>
  );
};

export default WhatWeDo;
