import React, { useState, useEffect, useRef } from "react";
import { motion, useInView } from "framer-motion";
import axios from "axios";

const Section5 = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch achievement content and image
        const achievementResponse = await axios.get(
          "https://back.onekoocoffee.com/api/achievement/get-achievements"
        );
        const lastAchievement = achievementResponse.data.slice(-1)[0];

        // Update states with the last item data
        setImage(lastAchievement?.Image || "");
        setTitle(lastAchievement?.Title || "");
        setContent(lastAchievement?.Description || "");
        setLoading(false);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  // Ref and animation control for the text section
  const textRef = useRef(null);
  const textInView = useInView(textRef, { once: true });

  // Ref and animation control for the images
  const imageRef = useRef(null);
  const imageInView = useInView(imageRef, { once: true });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <div className="max-w-auto ml-[1rem] py-10 justify-center mt-10">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[80%] justify-center max-md:ml-0 max-md:w-full">
            <motion.div
              className="flex flex-col px-5 text-primary max-md:mt-10"
              transition={{ duration: 1 }}
              ref={textRef}
            >
              <div className="text-4xl font-semibold">{title}</div>
              <motion.p
                initial={{ height: "auto" }}
                animate={{ height: isExpanded ? "auto" : "6rem" }}
                className={`overflow-hidden text-start transition-all md:w-[80%] duration-500 ${
                  isExpanded ? "h-auto" : "h-24"
                }`}
              >
                {content}
              </motion.p>
              <button
                onClick={toggleExpansion}
                className="my-4 w-[30%] md:w-[13%] lg:w-[13%] self-center text-center text-white hover:text-primary bg-secondary hover:bg-white rounded-xl hover:border-2 hover:border-secondary p-2 focus:outline-none"
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </motion.div>
          </div>
          <motion.div
            className="flex flex-col ml-5 w-[43%] max-md:ml-0 max-md:w-full"
            ref={imageRef}
          >
            <div className="max-w-[100%]">
              <div className="flex max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-[100%] max-md:ml-0 max-md:w-full z-50">
                  {image && (
                    <div className="px-2">
                      <img
                        className="w-[20rem] h-[24rem] mt-16 object-cover rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-105"
                        src={`https://back.onekoocoffee.com/images/${image}`}
                        alt="carousel-img"
                      />
                    </div>
                  )}
                </div>
                <div className="flex flex-col ml-[-15rem] w-[100%] max-md:ml-0 max-md:w-full z-0">
                  <div className="shrink-0 mx-auto max-w-full bg-primary h-[33rem] w-[100%] hidden md:block lg:block" />
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Section5;
