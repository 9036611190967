import React from "react";
import DescriptionSection from "./DescriptionSection";
import ImageSection from "./ImageSection";

function CoffeeSupplyChain() {
  return (
    <main className="flex overflow-hidden h-auto flex-col items-center px-5 pt-12 bg-white max-md:px-5">
      <header>
        <h1 className="text-4xl text-primary">from our farms to your cup</h1>
        <h2 className="mt-2 text-2xl text-golden">trace every bean</h2>
      </header>
      <section className="self-stretch pl-9 mt-8 bg-white max-md:pl-5 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <DescriptionSection />
          <ImageSection />
        </div>
      </section>
    </main>
  );
}

export default CoffeeSupplyChain;
