import React from "react";

function PackageOption({ src, alt }) {
  return (
    <div className="flex flex-col w-6/12 max-md:ml-5 max-md:w-full">
      <img
        loading="lazy"
        src={src}
        alt={alt}
        className="object-contain grow w-full aspect-[0.96] max-md:mt-10"
      />
    </div>
  );
}

export default PackageOption;
