import React from "react";
import { FaShoppingCart } from "react-icons/fa";
import { motion } from "framer-motion";

// Animation variants for the cards
const cardVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { opacity: 1, scale: 1 },
};

const Cart = ({ products, addToCart }) => {
  return (
    <React.Fragment>
      {/* Product Grid */}
      <div className="flex flex-col mt-[10rem] md:mt-[12rem] px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {products &&
            products.map((product, index) => (
              <motion.div
                key={index}
                className="bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
                variants={cardVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: index * 0.1 }}
              >
                <div className="relative">
                  <img
                    src={`https://back.onekoocoffee.com/images/${product.image_url}`}
                    alt={product.title}
                    className="w-full h-48 object-cover"
                  />
                </div>
                <div className="p-4">
                  <h2 className="text-lg font-semibold mb-2 text-gray-800">
                    {product.title}
                  </h2>
                  <p className="text-gray-600 mb-4">{product.description}</p>
                  <hr className="my-2" />
                  <div className="flex justify-between items-center mt-4">
                    <span className="text-lg font-bold text-gray-900">
                      ${product.price}
                    </span>
                    <button
                      onClick={() => addToCart(product)}
                      className="flex items-center justify-center w-10 h-10 bg-white text-primary rounded-full shadow-md hover:bg-secondary transition-colors duration-300"
                    >
                      <FaShoppingCart />
                    </button>
                  </div>
                </div>
              </motion.div>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Cart;
