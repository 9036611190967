import React from "react";
import SectionTitle from "./SectionTitle";
import SectionSubtitle from "./SectionSubtitle";
import TextBlock from "./TextBlock.jsx";
import ImageWithText from "./ImageWithText";

const HistoryEstablishment = () => {
  const imageTextBlocks = [
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/32928fb1f320ab328afd13bead806019543826e6a801ed013546cac92b044ef8?placeholderIfAbsent=true&apiKey=0e2569e29d1d4216b28e8cc6f702b661",
      title: "Sustainable Sourcing and Quality Production",
      content:
        "Partnered with OCFCU to secure a consistent supply of high-quality, organic Arabica coffee. Our advanced processing facility ensures that every batch of roasted and ground coffee upholds the highest standards of sustainability.",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/3c7dfffe66ef77d024efcd921e1fcbfcc4a3517937dbacdb286e655e0c2d5207?placeholderIfAbsent=true&apiKey=0e2569e29d1d4216b28e8cc6f702b661",
      title: "Building Connections",
      content:
        "Cultivated relationships with wholesalers, supermarkets, restaurants, and corporate clients, expanding Onekoo Coffee's market presence and reach.",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/3a020f21144cf34bf0f5610122742e1849d6386fca231fe8c3ea4a8612f37a40?placeholderIfAbsent=true&apiKey=0e2569e29d1d4216b28e8cc6f702b661",
      title: "Brand Identity and Storytelling",
      content:
        "Established Onekoo Coffee as a brand that captures the essence of Ethiopian coffee heritage. We celebrate the farmers' dedication and the meticulous cultivation of our coffee, offering a product rich in history and quality.",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/eb8f445dd0fdfae6fb77c6c714a269d557aee86776f88e5ff73f31c78626c391?placeholderIfAbsent=true&apiKey=0e2569e29d1d4216b28e8cc6f702b661",
      title: "Strategic Insights and Growth",
      content:
        "Implemented market intelligence to stay informed about consumer preferences and competitor activities. Developed strategies to capitalize on opportunities and address market challenges effectively.",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/bf2fc99044487986a7399c898f3fc436583dce7b6b48a1411430d952b6bbd935?placeholderIfAbsent=true&apiKey=0e2569e29d1d4216b28e8cc6f702b661",
      title: "Innovative Marketing and Engagement",
      content:
        "Partnered with leading advertising agencies to boost our brand's visibility and foster robust customer relationships, positioning Onekoo Coffee as a top choice for premium coffee.",
    },
  ];

  return (
    <main className="flex overflow-hidden flex-col items-center px-20 h-auto pt-40 pb-14 bg-secondary max-md:px-5">
      <SectionTitle text="History and Establishment" />
      <SectionSubtitle text="From Coffee Roots to Global Cup" />
      <section className="flex flex-wrap gap-5 justify-between mt-16 w-full text-xl font-bold text-white max-w-[1025px] max-md:mt-10 max-md:max-w-full">
        <h3>The Heart of Coffee</h3>
        <h3>A New Dawn for Farmers</h3>
      </section>
      <section className="self-stretch mt-12 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <TextBlock
            text="The journey of coffee begins in Oromia, Ethiopia—the birthplace of this cherished beverage. For the Oromo people, coffee is more than a drink; it's integral to trade, spiritual life, and community cohesion. Despite its cultural significance, Oromia faces challenges such as low market prices and climate change, which impact the livelihoods of smallholder farmers who cultivate 65% of Ethiopia's coffee."
            className="w-[41%] max-md:ml-0 max-md:w-full"
          />
          <div className="flex flex-col ml-5 w-[18%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b6a975e28377d368c28717705b47e7d82e1b98a4db6ba0b7194280267451d84e?placeholderIfAbsent=true&apiKey=0e2569e29d1d4216b28e8cc6f702b661"
              alt="Coffee cultivation in Oromia"
              className="object-contain grow shrink-0 max-w-full aspect-[0.71] w-[195px] max-md:mt-10"
            />
          </div>
          <TextBlock
            text="To address these challenges, the Oromia Coffee Farmers' Cooperative Union (OCFCU) was founded with a mission to support farmers through social protection, quality assurance, and sustainable practices. Since its establishment on January 30, 2021, Onekoo Coffee has embraced this mission, offering traceable, organic Arabica coffee directly from coffee's origins."
            className="w-[41%] max-md:ml-0 max-md:w-full"
          />
        </div>
      </section>
      {imageTextBlocks.map((block, index) => (
        <ImageWithText
          key={index}
          imageSrc={block.imageSrc}
          title={block.title}
          content={block.content}
        />
      ))}
      <h3 className="self-center mt-24 text-xl font-bold text-center text-white max-md:mt-10">
        Join Us in Our Journey
      </h3>
      <p className="mt-12 ml-9 text-xl text-white text-start md:text-center lg:text-center max-md:mt-10 max-md:max-w-full">
        Every cup of Onekoo Coffee embodies our commitment to quality,
        sustainability, and community. Experience the rich legacy of Ethiopian
        coffee and support the farmers who make it possible. Enjoy a product
        that represents both exceptional taste and a dedication to positive
        impact.
      </p>
    </main>
  );
};

export default HistoryEstablishment;
