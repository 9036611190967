import React from "react";

function ProductDescription({ text, highlight }) {
  const baseClasses = "mt-16 text-xl text-start max-md:mt-10 max-md:max-w-full";
  const highlightClasses = highlight ? "text-yellow-950" : "";

  return <p className={`${baseClasses} ${highlightClasses}`}>{text}</p>;
}

export default ProductDescription;
