import React from "react";
import LocationSection from "./LocationSection";
import LocationItem from "./LocationItem";

// Import each image individually
import globalImage from "../../../assets/images/global.jpg";
import lidetaImage from "../../../assets/images/ldeta.jpg";
import airportImage from "../../../assets/images/airport.jpg";
import boleBranchImage from "../../../assets/images/boleBranch.jpg";
import palaceImage from "../../../assets/images/palace.png";
import boleImage from "../../../assets/images/bole.png";
import kazaImage from "../../../assets/images/kaza.png";

const currentLocations = [
  {
    imageSrc: globalImage,
    description:
      "Around Global Hotel: Located in Mikwer Plaza, our café offers a refined setting for enjoying premium coffee.",
  },
  {
    imageSrc: lidetaImage,
    description:
      "Lideta Café branch: Situated in the Awash Bank building, this location is perfect for a convenient coffee break.",
  },
  {
    imageSrc: airportImage,
    description:
      "Airport branch: need a cup of coffee before your flight or after? Onekoo airport branch located inside the airport has you covered.",
  },
  {
    imageSrc: boleBranchImage,
    description:
      "Bole branch: Found within the Africa Development Bank building, our café caters to busy professionals and coffee enthusiasts.",
  },
];

const futureLocations = [
  {
    imageSrc: palaceImage,
    description:
      "Ethiopian Palace Compound: A new café opening soon, enhancing our accessibility in a prominent area.",
  },
  {
    imageSrc: boleImage,
    description:
      "Around Bole: Another new café will be launched in the Oromia Cooperative Bank Head Office, serving the heart of the business district.",
  },
  {
    imageSrc: kazaImage,
    description:
      "Kazanchis: We're also introducing a café in the Oda Tower, a bustling spot for enjoying our coffee.",
  },
];

function CafeLocations() {
  return (
    <main className="flex h-full flex-col items-center px-20 pt-6 pb-16 bg-white max-md:px-5">
      <div className="flex flex-col w-full max-w-[1211px] max-md:max-w-full">
        <section className="self-center w-full max-w-[1228px] max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            <LocationSection
              title="Discover Our Cafés"
              description="Experience the rich flavors and inviting atmosphere of Onekoo Coffee at our key locations in Addis Ababa:"
              locations={currentLocations}
            />
            <LocationSection
              title="Exciting Future Expansions"
              description="We're expanding our reach to bring Onekoo Coffee to even more locations:"
              locations={futureLocations}
              isReversed={true}
            />
          </div>
        </section>
      </div>
    </main>
  );
}

export default CafeLocations;
