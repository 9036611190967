import React, { useState } from "react";
import { motion } from "framer-motion";

const Tab = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = ["Vision", "Mission", "Values", "Objectives", "Strategies"];
  const tabContent = [
    <div>
      <h2 className="text-2xl font-bold mb-2 text-start">Vision</h2>
      <p className="text-start text-lg md:text-xl">
        Onekoo Coffee is the most popular and first choice coffee brand in
        Africa by 2030 G.C.
      </p>
    </div>,
    <div>
      <h2 className="text-2xl font-bold mb-2 text-start">Mission</h2>
      <p className="text-start text-lg md:text-xl">
        It is to create a modern practice in the coffee industry to benefit the
        coffee income by producing coffee with added value on behalf of the
        farmers involved in coffee production and OCFCU members.
      </p>
    </div>,
    <div className="flex flex-col lg:flex-row md:flex-row">
      {/* <h2 className="text-xl font-semibold mb-2 text-start">Values</h2> */}
      <p className="text-2xl font-bold text-start w-full">
        Values of the Onekoo Coffee:
      </p>
      <ul className="items-start flex-col">
        <li>Self-help</li>
        <li>Self-responsibility</li>
        <li className="text-start">Promoting culture of democracy</li>
        <li>Equality</li>
        <li>Equity</li>
        <li>Solidarity</li>
      </ul>
      <p className="mt-4 text-2xl font-bold text-start w-full">
        Ethical values of the members of the Union:
      </p>
      <ul className="flex-col">
        <li>Honesty</li>
        <li>Openness/accountability</li>
        <li>Participatory</li>
        <li>Social responsibility</li>
        <li>Caring for others</li>
      </ul>
    </div>,
    <div>
      <h2 className="text-2xl font-bold mb-2 text-start">Objectives</h2>
      <h3 className="text-xl font-medium text-start">
        <strong>Long-term objective</strong>
      </h3>
      <p className="text-lg md:text-xl text-start">
        The objective of placing Onekoo Coffee in the market is geared in
        acquisition and identifying spots at different corners of the city, the
        country, and the international.
      </p>
      <h3 className="text-xl font-medium mt-4 text-start">
        <strong>Short-term objective</strong>
      </h3>
      <p className="text-lg md:text-xl text-start">
        To this effect on Addis Ababa local and international departure, we are
        providing café service, promoting and supplying packed roasted and
        ground coffee to create international and domestic business partnership.
      </p>
    </div>,
    <div>
      <h2 className="text-2xl font-bold mb-2 text-start">Strategies</h2>
      <p className="text-start text-lg md:text-xl">
        <strong>Modern Marketing Strategies for Onekoo Coffee:</strong>
      </p>
      <p className="mt-4 text-lg md:text-xl text-start">
        At Onekoo Coffee, we are committed to expanding our market reach and
        elevating our brand presence both locally and internationally. To
        achieve these goals, we have implemented a comprehensive sales
        representative system and modern marketing procedures designed to
        enhance our visibility and effectiveness.
      </p>
      <p className="mt-4 text-lg md:text-xl text-start">
        Our newly established sales representative system is a cornerstone of
        our modern marketing strategy. This system streamlines our approach,
        ensuring that our sales efforts are targeted and efficient. We are
        dedicated to improving our advertising and promotional activities to
        better showcase Onekoo Coffee products. Our initiatives include
        leveraging various media channels, participating in key bazaars and
        exhibitions, and engaging in face-to-face advertising to build a strong
        presence in both domestic and international markets.
      </p>
      <p className="mt-4 text-lg md:text-xl text-start">
        To address the challenges we encounter in our marketing efforts, we have
        adopted a cutting-edge procedure known as kaizen, which is known as the
        SCORE project. This methodology helps us systematically identify and
        resolve issues, ensuring that our marketing department operates at peak
        efficiency. SCORE enables us to continuously refine our strategies and
        adapt to market dynamics, ultimately enhancing our ability to promote
        Onekoo Coffee effectively.
      </p>
      <p className="mt-4 text-lg md:text-xl text-start">
        Through these innovations, we aim to elevate the reputation of Onekoo
        Coffee, connecting with coffee enthusiasts around the world and
        solidifying our position as a leading name in premium Ethiopian coffee.
      </p>
    </div>,
  ];

  return (
    <div className="w-full lg:max-w-4xl md:max-w-2xl mx-auto mt-10">
      <div className="flex flex-wrap md:flex-nowrap justify-between border-b border-primary">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`cursor-pointer text-lg sm:text-xl md:text-2xl py-2 px-4 font-medium ${
              activeTab === index
                ? "text-primary border-b-4 border-primary"
                : "text-secondary"
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab}
          </div>
        ))}
      </div>
      <motion.div
        key={activeTab}
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        transition={{ duration: 0.3 }}
        className="p-4 border-l-2 border-b-2 border-r-2 border-primary"
      >
        {tabContent[activeTab]}
      </motion.div>
    </div>
  );
};

export default Tab;
