import React from "react";

const ImageWithText = ({ imageSrc, title, content }) => (
  <div className="flex flex-wrap gap-10 items-start mt-24 ml-3.5 w-full text-xl text-white text-start max-w-[1161px] max-md:mt-10 max-md:max-w-full">
    <img
      loading="lazy"
      src={imageSrc}
      alt={title}
      className="object-contain shrink-0 mt-5 max-w-full aspect-[0.89] w-[111px]"
    />
    <div className="flex flex-col grow shrink-0 basis-0 w-fit max-md:max-w-full">
      <h3 className="self-start font-bold max-md:max-w-full">{title}</h3>
      <p className="mt-7 max-md:max-w-full">{content}</p>
    </div>
  </div>
);

export default ImageWithText;
