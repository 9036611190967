import React from "react";

function CuppingProcess({ steps }) {
  return (
    <div className="flex flex-wrap gap-10 mt-6 text-xl text-white max-md:max-w-full">
      {steps.map((step, index) => (
        <div
          key={index}
          className="grow px-6 pt-4 pb-6 bg-secondary text-start w-fit max-md:px-5 max-md:max-w-full"
        >
          {step}
        </div>
      ))}
    </div>
  );
}

export default CuppingProcess;
