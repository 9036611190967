import React, { useEffect, useState } from "react";
import axios from "axios";
import Banner from "./Banner";
import Section3 from "./Section3";
import Section5 from "./Section5";

const Involve = () => {
  const [carousel1Images, setCarousel1Images] = useState([]);
  const [carousel2Images, setCarousel2Images] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response1 = await axios.get(
          "https://back.onekoocoffee.com/api/involvement/all-involvement"
        );
        // console.log(
        //   response1.data.map((value) => {
        //     return value.Image;
        //   })
        // );
        console.table(response1.data);
        const response2 = await axios.get(
          "https://back.onekoocoffee.com/api/achievement/get-achievements"
        );
        // console.log(
        //   response2.data.map((value) => {
        //     return value.Image;
        //   })
        // );
        console.table(response2.data);

        setCarousel2Images(
          response2.data.map((value) => {
            return value.Image;
          })
        );

        setCarousel1Images(
          response1.data.map((value) => {
            return value.Image;
          })
        ); // Adjust this based on your API response structure
        // setCarousel2Images(response2.data.images); // Adjust this based on your API response structure
        setLoading(false);
      } catch (error) {
        console.error("There was an error fetching the images!", error);
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <Banner />
      <div className="w-auto md:px-24 lg:px-24 h-auto bg-white">
        <Section3 />
        <Section5 />
      </div>
    </React.Fragment>
  );
};

export default Involve;
