import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function BlogSection() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          "https://back.onekoocoffee.com/api/blog/get-blog"
        );
        setBlogs(response.data);
        setLoading(false);
        console.table(response.data);
      } catch (error) {
        console.error("Error fetching blog:", error);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-white py-12 overflow-x-hidden">
      <div className="max-w-7xl mx-5 md:mx-auto px-4">
        <h2 className="text-4xl font-bold text-primary mb-8">Our Blog</h2>
        <Slider {...settings}>
          {blogs.map((post) => (
            <div key={post.id} className="px-4">
              <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                <img
                  src={`https://back.onekoocoffee.com/images/${post.Image}`}
                  alt={post.Title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-2xl font-semibold text-brown-800">
                    {post.Title}
                  </h3>
                  <p className="text-brown-600 mt-2">{post.Description}</p>
                  <button className="mt-4 inline-block bg-secondary text-primary py-2 px-4 rounded hover:bg-primary hover:text-white">
                    Read More
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
