// import React from "react";
// import { FaHome, FaPhone, FaPrint } from "react-icons/fa";
// import { MdEmail } from "react-icons/md";

// const ContactInfo = () => {
//   const contactDetails = [
//     {
//       icon: <FaHome />,
//       text: "Gelan, Ethiopia",
//       alt: "Location icon",
//     },
//     {
//       icon: <MdEmail />,
//       text: "info@onekoocoffee.com",
//       alt: "Email icon",
//     },
//     {
//       icon: <FaPhone />,
//       text: "+251 91-234-5678",
//       alt: "Phone icon",
//     },
//     {
//       icon: <FaPrint />,
//       text: "+251 91-234-5678",
//       alt: "Alternative phone icon",
//     },
//   ];

//   return (
//     <section className="flex gap-9 text-orange-200 max-md:mt-10">
//       <div className="shrink-0 w-1 border border-black border-solid h-[372px]" />
//       <div className="flex flex-col grow shrink-0 items-start self-start mt-2 basis-0 w-fit">
//         <h2 className="self-center text-3xl mb-12 max-md:mb-10">Find Us</h2>
//         {contactDetails.map((detail, index) => (
//           <div
//             key={index}
//             className={`flex gap-5 justify-between ${
//               index > 0 ? "mt-8" : ""
//             } max-w-full text-xl w-[272px]`}
//           >
//             <img
//               loading="lazy"
//               src={detail.icon}
//               alt={detail.alt}
//               className="object-contain shrink-0 w-10 aspect-[0.89]"
//             />
//             <div className="my-auto">{detail.text}</div>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// };

// export default ContactInfo;

import React from "react";
import { FaHome, FaPhone, FaPrint } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const ContactInfo = () => {
  const contactDetails = [
    {
      icon: <FaHome />,
      text: "Gelan, Ethiopia",
      alt: "Location icon",
    },
    {
      icon: <MdEmail />,
      text: "info@onekoocoffee.com",
      alt: "Email icon",
    },
    {
      icon: <FaPhone />,
      text: "+251 91-234-5678",
      alt: "Phone icon",
    },
    {
      icon: <FaPrint />,
      text: "+251 91-234-5678",
      alt: "Alternative phone icon",
    },
  ];

  return (
    <section className="flex gap-9 px-5 text-orange-200 max-md:mt-10 self-center">
      {/* <div className="shrink-0 w-1 border border-black border-solid h-[372px]" /> */}
      <div className="flex flex-col grow shrink-0 items-start self-start mt-2 basis-0 w-fit">
        <h2 className="self-center text-3xl mb-12 max-md:mb-5">
          Contact us here!
        </h2>
        {contactDetails.map((detail, index) => (
          <div
            key={index}
            className={`flex gap-5 justify-between ${
              index > 0 ? "mt-2" : ""
            } max-w-full text-xl w-[272px]`}
          >
            <div className="shrink-0 w-10 h-10 flex items-center text-start justify-center">
              {detail.icon}
            </div>
            <div className="my-auto self-start">{detail.text}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ContactInfo;
