import React from "react";

const TextBlock = ({ text, className }) => (
  <div className={`flex flex-col ${className}`}>
    <p className="self-stretch my-auto text-xl text-white text-start max-md:mt-10 max-md:max-w-full">
      {text}
    </p>
  </div>
);

export default TextBlock;
