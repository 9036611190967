import React from "react";
import CuppingStep from "./CuppingStep.jsx";
import CuppingProcess from "./CuppingProcess";
import uping5 from "../../assets/images/FarmtoCup/uping5.jpg";
import uping6 from "../../assets/images/FarmtoCup/uping6.jpg";
import award from "../../assets/images/FarmtoCup/award.jpg";

const cuppingSteps = [
  {
    imageSrc: uping5,
    description:
      "Grinding: Fresh coffee beans are ground to a precise coarseness, setting the stage for a rich, aromatic experience.",
  },
  {
    imageSrc: uping6,
    description:
      "Smelling: The ground coffee is examined for its captivating aroma, revealing the initial hints of its unique fragrance.",
  },
];

const cuppingProcessSteps = [
  "Brewing: Hot water is poured over the grounds, allowing the coffee to steep and release its full spectrum of flavors.",
  "Crusting: As the coffee brews, a fragrant crust forms on the surface. This crust is gently broken to release a burst of aromatic notes for further evaluation.",
  "Tasting: The coffee is slurped with enthusiasm, allowing its flavors to dance on the palate. This step reveals the coffee's intricate notes, balance, and aftertaste.",
];

function CoffeeCupping() {
  return (
    <main className="flex overflow-hidden h-auto flex-col justify-center items-center px-20 py-11 bg-white max-md:px-5">
      <div className="flex flex-col w-full max-w-[1195px] max-md:max-w-full">
        <h1 className="self-center text-4xl font-bold text-center text-primary">
          Coffee cupping
        </h1>
        <p className="self-center mt-6 text-2xl text-center text-golden">
          1st in flavor, aroma and quality
        </p>
        <p className="self-center mt-20 text-3xl text-primary max-md:mt-10 max-md:max-w-full">
          Coffee cupping is an artful process designed to explore and savor the
          rich complexity of coffee flavors. Here's a glimpse into the steps of
          this sensory journey:
        </p>
        <section className="mt-20 max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            {cuppingSteps.map((step, index) => (
              <CuppingStep
                key={index}
                imageSrc={step.imageSrc}
                description={step.description}
              />
            ))}
          </div>
        </section>
        <CuppingProcess steps={cuppingProcessSteps} />
        <section className="mt-14 max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <h2 className="mt-10 text-4xl text-primary shadow-lg p-5 max-md:max-w-full">
                At Onekoo Coffee, we are thrilled to have secured both 1st and
                2nd place in a recent coffee cupping competition, a testament to
                our commitment to crafting exceptional, award-winning coffee.
              </h2>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                src={award}
                className="object-contain grow w-full aspect-[1.42] max-md:mt-10 max-md:max-w-full"
              />
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default CoffeeCupping;
