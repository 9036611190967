import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BigBanner from "../components/Shop/Big Banner";
import Filter from "../components/Shop/Filter";
// import CenterBanner from "../components/Shop/CenterBanner";
import Cart from "../components/Shop/Cart";
import Product from "../components/Shop/Product";
// import SpecialProduct from "../components/Shop/SpecialProduct";
import LeftAd from "../components/Shop/LeftAd";
import RightAd from "../components/Shop/RightAd";
import AdBanner from "../components/Shop/AdBanner";
import { ShoppingBasket } from "lucide-react";

const Shop = () => {
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [products, setProducts] = useState([]);
  const [user, setUser] = useState(null);
  const [isAuth, setIsAuth] = useState(false);

  const addToCart = (product) => {
    setCart([...cart, product]);
  };
  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios
      .get(`https://back.onekoocoffee.com/api/products/get-all`) // Replace with your API endpoint
      .then((response) => {
        console.log(response.data.products);
        setProducts(response.data.products);
        setIsAuth(true);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setIsAuth(false);
        navigate("/account");
      });
  }, []);

  const fetchAgain = () => {
    axios
      .get(`https://back.onekoocoffee.com/api/products/get-all`) // Replace with your API endpoint
      .then((response) => {
        console.log(
          response.data.products.filter((product) => {
            return product.category_id === 1;
          })
        );
        setProducts(response.data.products);
        return response.data.products;
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };
  return (
    <React.Fragment>
      {/* Desktop and Tablet View */}
      <div className="hidden md:block lg:block sm:hidden pt-32 w-[100%] bg-white">
        <div className="my-4 w-[100%] h-[100%] flex flex-row bg-white">
          <div className=" w-[20%]">
            <Filter
              products={products}
              fetchAgain={fetchAgain}
              setProducts={setProducts}
            />
          </div>
          <div className="flex flex-col w-[70%]">
            <p className="text-5xl text-primary">Our Products</p>
            <Product products={products} addToCart={addToCart} />
          </div>
          <div className="sticky top-32  w-auto">
            <button
              onClick={() => {
                navigate("/checkout", { state: cart });
              }}
              className="my-4 relative p-3 bg-golden border text-slate-50 border-primary rounded-xl"
            >
              <ShoppingBasket className="" />
              {cart.length !== 0 && (
                <div className="absolute -top-2 right-0 min-w-[22px] min-h-[22px] rounded-md text-black bg-slate-50">
                  {cart.length}
                </div>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="block md:hidden lg:hidden bg-white">
        <div>
          <div>
            <div>
              <Filter
                products={products}
                fetchAgain={fetchAgain}
                setProducts={setProducts}
              />
            </div>
            <div>
              <p className="text-3xl text-primary">Our Products</p>
              <Product products={products} addToCart={addToCart} />
            </div>
            <div>
              <button
                onClick={() => {
                  navigate("/checkout", { state: cart });
                }}
                className="my-4 relative p-3 bg-golden border text-slate-50 border-primary rounded-xl"
              >
                <ShoppingBasket className="" />
                {cart.length !== 0 && (
                  <div className="absolute -top-2 right-0 min-w-[22px] min-h-[22px] rounded-md text-black bg-slate-50">
                    {cart.length}
                  </div>
                )}
              </button>
            </div>
          </div>
          {/* <div>
            <div>
              <LeftAd />
            </div>
            <div>
              <p className="text-3xl text-primary">Our Special Products</p>
              <Product products={products} addToCart={addToCart} />
            </div>
            <div>
              <RightAd />
            </div>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Shop;
