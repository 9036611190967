import React from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

export default function ArticleSection() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(
          "https://back.onekoocoffee.com/api/article/get-article"
        );
        setArticles(response.data);
        console.table(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Articles:", error);
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
  };

  return (
    <div className="max-w-3xl mx-auto py-12">
      <h1 className="text-4xl font-bold text-primary text-center mb-8">
        Our Articles
      </h1>
      <Slider {...settings}>
        {articles.map((item) => (
          <motion.div
            key={item.id}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="p-4"
          >
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img
                src={`https://back.onekoocoffee.com/images/${item.Image}`}
                alt={item.Title}
                className="w-full h-56 object-cover"
              />
              <div className="p-6">
                <h3 className="text-2xl font-bold text-primary mb-2">
                  {item.title}
                </h3>
                <p className="text-gray-600">{item.Ldescription}</p>
                <button className="mt-4 inline-block bg-secondary text-primary py-2 px-4 rounded hover:bg-primary hover:text-white transition-colors duration-300">
                  Read More
                </button>
              </div>
            </div>
          </motion.div>
        ))}
      </Slider>
    </div>
  );
}
