// import React from "react";
// import pdf from "../../assets/images/dictionary.pdf";
// import menu from "../../assets/images/onekoo_coffee_menu_psyann_graphics_2024.png";
// import { RiFileDownloadFill } from "react-icons/ri";
// import { useState } from "react";
// import { useEffect } from "react";
// import axios from "axios";

// const Download = () => {
//   const [downloads, setDownloads] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchDownloads = async () => {
//       try {
//         const response = await axios.get(
//           "https://back.onekoocoffee.com/api/do/get-do"
//         );
//         setDownloads(response.data);
//         console.table(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching do:", error);
//         setLoading(false);
//       }
//     };

//     fetchDownloads();
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }
//   return (
//     <div className="flex flex-col items-center mt-12 p-8 border-2 border-secondary rounded-lg bg-white shadow-md max-w-7xl mx-auto">
//       <h2 className="text-4xl font-bold text-primary mb-6">Downloads</h2>
//       <div className="flex flex-col sm:flex-row items-center justify-between w-full gap-8">
//         <div className="flex items-center gap-4 w-full sm:w-1/2 bg-gray-100 p-4 rounded-lg hover:shadow-lg transition-shadow">
//           <p className="text-lg font-medium text-secondary">Company Profile</p>
//           <a href={pdf} download="dictionary.pdf" className="ml-auto">
//             <RiFileDownloadFill className="text-primary w-10 h-10" />
//           </a>
//         </div>
//         {/* <div className="flex items-center gap-4 w-full sm:w-1/2 bg-gray-100 p-4 rounded-lg hover:shadow-lg transition-shadow">
//           <p className="text-lg font-medium text-secondary">Our Menu</p>
//           <a
//             href={menu}
//             download="onekoo_coffee_menu_psyann_graphics_2024.png"
//             className="ml-auto"
//           >
//             <RiFileDownloadFill className="text-primary w-10 h-10" />
//           </a>
//         </div> */}
//       </div>
//     </div>
//   );
// };

// export default Download;

import React from "react";
import pdf from "../../assets/images/dictionary.pdf";
import menu from "../../assets/images/onekoo_coffee_menu_psyann_graphics_2024.png";
import { RiFileDownloadFill } from "react-icons/ri";
import { useState, useEffect } from "react";
import axios from "axios";

const Download = () => {
  const [downloads, setDownloads] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDownloads = async () => {
      try {
        const response = await axios.get(
          "https://back.onekoocoffee.com/api/download/get-download"
        );
        setDownloads(response.data);
        console.table(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching do:", error);
        setLoading(false);
      }
    };

    fetchDownloads();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  // Slice the last 3 items from the downloads array
  const lastThreeDownloads = downloads.slice(-3);

  return (
    <div className="flex flex-col items-center mt-12 p-8 border-2 border-secondary rounded-lg bg-white shadow-md max-w-7xl mx-auto">
      <h2 className="text-4xl font-bold text-primary mb-6">Downloads</h2>
      <div className="flex flex-col sm:flex-row items-center justify-between w-full gap-8">
        {lastThreeDownloads.map((download, index) => (
          <div
            key={index}
            className="flex items-center gap-4 w-full sm:w-1/2 bg-gray-100 p-4 rounded-lg hover:shadow-lg transition-shadow"
          >
            {/* <p className="text-lg font-medium text-secondary">
              {download.title}
            </p> */}
            {/* <a href={download.url} download className="ml-auto"> */}
            <a
              href={`https://back.onekoocoffee.com/images/${download.Image}`}
              download
              className="ml-auto"
            >
              <RiFileDownloadFill className="text-primary w-10 h-10" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Download;
