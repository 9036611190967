import React from "react";

const CoffeeRoastType = ({ color, label }) => (
  <div className="flex flex-col mt-10">
    <div className={`flex shrink-0 ${color} h-[39px]`} />
    <div className="mt-14 max-md:mt-10 text-xl text-white">{label}</div>
  </div>
);

export default CoffeeRoastType;
