import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import InvolveNAchiev from "../components/Media/Involve";
import Blog from "../components/Media/BlogList";
import Award from "../components/Media/Award";
import Article from "../components/Media/Article";
import Download from "../components/Media/Download";

const Media = () => {
  // Animation controls for each section
  const controlInvolve = useAnimation();
  const controlBlog = useAnimation();
  const controlAward = useAnimation();
  const controlArticle = useAnimation();
  const controlDownload = useAnimation();

  // Intersection observers for each section
  const [refInvolve, inViewInvolve] = useInView({ triggerOnce: true });
  const [refBlog, inViewBlog] = useInView({ triggerOnce: true });
  const [refAward, inViewAward] = useInView({ triggerOnce: true });
  const [refArticle, inViewArticle] = useInView({ triggerOnce: true });
  const [refDownload, inViewDownload] = useInView({ triggerOnce: true });

  // Trigger animations when in view
  React.useEffect(() => {
    if (inViewInvolve) {
      controlInvolve.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8 },
      });
    }
    if (inViewBlog) {
      controlBlog.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8 },
      });
    }
    if (inViewAward) {
      controlAward.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8 },
      });
    }
    if (inViewArticle) {
      controlArticle.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8 },
      });
    }
    if (inViewDownload) {
      controlDownload.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8 },
      });
    }
  }, [
    controlInvolve,
    controlBlog,
    controlAward,
    controlArticle,
    controlDownload,
    inViewInvolve,
    inViewBlog,
    inViewAward,
    inViewArticle,
    inViewDownload,
  ]);

  return (
    <div className="overflow-x-hidden md:overflow-x-visible lg:overflow-x-visible">
      <motion.div
        ref={refInvolve}
        initial={{ opacity: 0, y: 50 }}
        animate={controlInvolve}
      >
        <InvolveNAchiev />
      </motion.div>

      <motion.div
        ref={refBlog}
        initial={{ opacity: 0, y: 50 }}
        animate={controlBlog}
      >
        <Blog />
      </motion.div>

      <motion.div
        ref={refAward}
        initial={{ opacity: 0, y: 50 }}
        animate={controlAward}
      >
        <Award />
      </motion.div>

      <motion.div
        ref={refArticle}
        initial={{ opacity: 0, y: 50 }}
        animate={controlArticle}
      >
        <Article />
      </motion.div>

      <motion.div
        ref={refDownload}
        initial={{ opacity: 0, y: 50 }}
        animate={controlDownload}
      >
        <Download />
      </motion.div>
    </div>
  );
};

export default Media;
