import React from "react";
import { Link } from "react-router-dom";
import {
  FaPhoneSquare,
  FaLink,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
  FaYoutube,
  FaTelegramPlane,
  FaWhatsapp,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const SocialPresence = () => {
  return (
    <section className="flex flex-col items-center mt-1.5 text-3xl text-white max-md:mt-5">
      <h2 className="self-stretch">Our social media accounts</h2>
      <div className="flex flex-col max-md:mt-10">
        <div className="flex gap-6 mt-9 text-white">
          <Link
            className="shrink-0 w-9 aspect-square"
            onClick={() => window.scrollTo(0, 0)}
            to={`https://www.facebook.com`}
          >
            <FaFacebookF className="w-10 h-10" />
          </Link>
          <Link
            className="shrink-0 w-9 aspect-square"
            onClick={() => window.scrollTo(0, 0)}
            to={`https://www.facebook.com`}
          >
            <FaInstagram className="w-10 h-10" />
          </Link>
          <Link
            className="shrink-0 w-9 aspect-square"
            onClick={() => window.scrollTo(0, 0)}
            to={`https://www.facebook.com`}
          >
            <FaXTwitter className="w-10 h-10" />
          </Link>
          <Link
            className="shrink-0 w-9 aspect-square"
            onClick={() => window.scrollTo(0, 0)}
            to={`https://www.facebook.com`}
          >
            <FaTiktok className="w-10 h-10" />
          </Link>
        </div>
        <div className="flex gap-6 mt-4 text-white">
          <Link
            className="shrink-0 w-9 aspect-square"
            onClick={() => window.scrollTo(0, 0)}
            to={`https://www.facebook.com`}
          >
            <FaYoutube className="w-10 h-10" />
          </Link>
          <Link
            className="shrink-0 w-9 aspect-square"
            onClick={() => window.scrollTo(0, 0)}
            to={`https://www.facebook.com`}
          >
            <FaTelegramPlane className="w-10 h-10" />
          </Link>
          <Link
            className="shrink-0 w-9 aspect-square"
            onClick={() => window.scrollTo(0, 0)}
            to={`https://www.facebook.com`}
          >
            <FaWhatsapp className="w-10 h-10" />
          </Link>
          <Link
            className="shrink-0 w-9 aspect-square"
            onClick={() => window.scrollTo(0, 0)}
            to={`https://www.facebook.com`}
          >
            <FaLinkedinIn className="w-10 h-10" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SocialPresence;
