import React from "react";

function ImageSection() {
  return (
    <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0f5488dc790b53618cc0e5b296ca5d8c0bcebcb5c53b89b628d31eb3907221ec?placeholderIfAbsent=true&apiKey=0e2569e29d1d4216b28e8cc6f702b661"
        alt="Coffee supply chain illustration"
        className="object-contain grow w-full aspect-[1.19] max-md:mt-2 max-md:max-w-full"
      />
    </div>
  );
}

export default ImageSection;
