import React from "react";
import PackageOption from "./PackageOption";
import CoffeeType from "./CoffeeType";
import roasted from "../../../assets/images/roasted.jpg";
import packag from "../../../assets/images/package.png";

function PremiumPackaging() {
  const packageOptions = [
    {
      src: packag,
      alt: "Coffee packaging option 1",
    },
    {
      src: roasted,
      alt: "Coffee packaging option 2",
    },
  ];

  const coffeeTypes = [
    {
      title: "Ground Coffee",
      description: "Available in 250 grams, 500 grams, 1 kg, and 2 kg.",
    },
    {
      title: "Roasted Coffee",
      description: "Available in 500 grams, 1 kg, and 2 kg.",
    },
  ];

  return (
    <section className="flex overflow-hidden flex-col justify-center items-center px-20 py-16 bg-white max-md:px-5">
      <div className="flex flex-col w-full max-w-[1103px] max-md:max-w-full">
        <h1 className="self-center text-4xl text-primary">Premium Packaging</h1>
        <div className="flex flex-col pl-3.5 mt-16 w-full max-md:mt-10 max-md:max-w-full">
          <p className="text-xl text-primary max-md:max-w-full">
            Each blend is meticulously processed and packaged in laminated
            aluminum foil bags with a valve to ensure maximum freshness. We
            offer various packaging options to fit your needs:
          </p>
          <div className="mt-10 ml-12 max-w-full w-[903px]">
            <div className="flex gap-20 max-md:flex-col">
              {packageOptions.map((option, index) => (
                <PackageOption key={index} src={option.src} alt={option.alt} />
              ))}
            </div>
          </div>
        </div>
        <div className="mt-10 w-full max-w-[1061px] max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            {coffeeTypes.map((type, index) => (
              <CoffeeType
                key={index}
                title={type.title}
                description={type.description}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PremiumPackaging;
